import React from 'react';

import SEO from "../components/common/layout/seo"
import PageLayout from "../components/common/layout/pageLayout";

const Careers = () => {
  return (
    <PageLayout>
      <SEO title="Careers" />
      <h1>Careers @ Hedera.in</h1>
      <div>
        <h3>Marketing</h3>
        <p>Looking for Interns / Part time / Full time. Students or MBA from reputed colleges can send their resumes to careers@hedera.in</p>
        <h3>Development</h3>
        <p>Hedera has open roles (Interns /Part time/ Full time) in development. React, React native with Knowledge in AWS services are preferred. Send your resumes to careers@hedera.in</p>
      </div>
    </PageLayout>
  );
}

export default Careers;
